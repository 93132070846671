import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { addSeconds, format, formatDistanceStrict, isAfter } from 'date-fns'
import lang from 'lang'
import { isAuthenticated } from 'services/selectors'
import { submitGroupMoodMeterPlot } from 'page-modules/mood-meter/service/actions'
import GroupTimer from 'page-modules/mood-meter/group-timer'
import PlotJourney from 'page-modules/mood-meter/plot-journey'
import { replace, timeDuration } from 'common/utils'

@connect(
  (state) => ({
    isAuthenticated: isAuthenticated(state),
    group: state.moodMeter.group.item,
  }),
  (dispatch) => ({
    submitGroupMoodMeterPlot: (groupId) => dispatch(submitGroupMoodMeterPlot(groupId)),
  }),
)
export default class InGroupPlot extends React.Component {
  static propTypes = {
    group: PropTypes.object,
    isAuthenticated: PropTypes.bool.isRequired,
    submitGroupMoodMeterPlot: PropTypes.func.isRequired,
  }

  componentDidMount() {
    if (!this.hasGroupStarted) {
      this.startCheckingGroupStartTime()
    }
  }

  get hasGroupStarted() {
    return isAfter(new Date(), new Date(this.props.group.start_time))
  }

  get startTimeDate() {
    return new Date(this.props.group.start_time)
  }

  get endTimeDate() {
    const seconds = timeDuration(this.props.group.duration).asSeconds()
    return addSeconds(this.startTimeDate, seconds)
  }

  startCheckingGroupStartTime() {
    this.groupStartTimeInterval = setInterval(() => {
      if (isAfter(new Date(), this.startTimeDate)) {
        this.stopCheckingGroupStartTime()
        this.forceUpdate()
      }
    }, 1000)
  }

  stopCheckingGroupStartTime() {
    clearInterval(this.groupStartTimeInterval)
    this.groupStartTimeInterval = null
  }

  renderStartedGroupStatus() {
    const { collected_moods } = this.props.group

    return (
      <>
        <p className="page-subtitle">
          {collected_moods}{' '}
          {collected_moods === 1
            ? lang.page.moodMeter.personHasPlottedTheirMood
            : lang.page.moodMeter.peopleHavePlottedTheirMood}
          {!this.props.isAuthenticated ? (
            <>
              <br />
              {`${lang.page.moodMeter.plotOpened}: ${format(
                this.startTimeDate,
                'MM-dd-yyyy hh:mm a',
              )}`}
            </>
          ) : null}
        </p>
        <GroupTimer
          duration={this.props.group.duration}
          closed={this.props.group.closed}
          startTimeDate={this.startTimeDate}
          endTimeDate={this.endTimeDate}
        />
      </>
    )
  }

  renderNotStartedGroupStatus() {
    const formatedStartDate = format(new Date(this.props.group.start_time), 'MMM dd, yyyy, hh:mm a')
    const formatedDuration = formatDistanceStrict(this.startTimeDate, this.endTimeDate)
    return (
      <p className="page-subtitle">
        {replace(lang.page.moodMeter.notStarted, [formatedStartDate, formatedDuration])}
      </p>
    )
  }

  renderPlotJourney() {
    if (this.props.group.closed) {
      return (
        <div className="grid-container margin-top-1">
          <div className="grid-x grid-padding-x align-center">
            <div className="cell">
              <h2 className="closed">{lang.page.moodMeter.closed}</h2>
            </div>
          </div>
        </div>
      )
    }
    return (
      <PlotJourney
        withGroupId={this.props.group.id}
        submitMoodMeterPlot={this.props.submitGroupMoodMeterPlot}
      />
    )
  }

  render() {
    return (
      <>
        <div className="hero-section">
          <div className="grid-container">
            <div className="grid-x grid-padding-x">
              <div className="small-12 medium-12 large-8 cell">
                {!this.props.isAuthenticated ? <h3>{lang.page.moodMeter.plotYourMood}</h3> : null}
                <h1 className="page-title">{this.props.group.name}</h1>
                {this.hasGroupStarted
                  ? this.renderStartedGroupStatus()
                  : this.renderNotStartedGroupStatus()}
              </div>
            </div>
          </div>
        </div>
        {this.hasGroupStarted ? this.renderPlotJourney() : null}
      </>
    )
  }
}
