import { AppState } from 'common/store/reducer'
import { AnyAction, Store } from 'redux'

export interface InitialPageData {
  action: AnyAction
  confirm: (state: AppState) => boolean
  error: (state: AppState) => boolean
}

export default function LoadPageData(store: Store, { action, confirm, error }: InitialPageData) {
  return new Promise<void>((resolve) => {
    store.dispatch(action)
    const unsubscribe = store.subscribe(() => {
      const state = store.getState()
      if (confirm(state) || error(state)) {
        unsubscribe()
        return resolve()
      }
    })
  })
}
