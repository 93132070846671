import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'

import lang from 'lang'
import Form from 'common/components/form'
import {
  changeMoodMeterInGroupFormField,
  resetMoodMeterInGroupFormData,
} from 'page-modules/mood-meter/in-group/actions'
import { urls } from 'common/routes'
import { replace } from 'common/utils'

@withRouter
@connect(
  (state) => ({
    code: state.moodMeter.forms.ingroup.data.code,
    dirty: state.moodMeter.forms.ingroup.dirty,
  }),
  (dispatch) => ({
    resetMoodMeterInGroupFormData: () => dispatch(resetMoodMeterInGroupFormData()),
    changeFormField: (key, value) => dispatch(changeMoodMeterInGroupFormField(key, value)),
  }),
)
export default class InGroupForm extends Form {
  static propTypes = {
    code: PropTypes.string,
    router: PropTypes.object,
    dirty: PropTypes.bool,
    resetMoodMeterInGroupFormData: PropTypes.func,
    changeFormField: PropTypes.func,
    hasCode: PropTypes.bool,
  }

  submitMoodMeterInGroupPlotHandler() {
    return (e) => {
      e.preventDefault()
      this.props.resetMoodMeterInGroupFormData()
      window.location = urls.moodMeterPlotInGroup(this.props.code)
    }
  }

  render() {
    return (
      <>
        <div className="hero-section">
          <div className="grid-container">
            <div className="grid-x grid-padding-x">
              <div className="small-12 medium-12 large-8 cell">
                <h1>{lang.page.moodMeter.groupPlot}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="grid-container margin-top-1">
          <div className="grid-x grid-padding-x">
            <div className="small-12 medium-8 large-6 cell">
              <h5>{lang.page.moodMeter.typeGroupCode}</h5>
              <form>
                <input
                  type="text"
                  onChange={this.createFieldHandler('code')}
                  required
                  pattern="alpha_numeric"
                  tabIndex="0"
                />
                {this.props.hasCode ? (
                  <p className="form-error is-visible">
                    {replace(lang.page.moodMeter.invalidCode, [this.props.router.query?.shortCode])}
                  </p>
                ) : null}
                <button
                  type="submit"
                  className="button submit save"
                  onClick={this.submitMoodMeterInGroupPlotHandler()}
                  disabled={!this.props.dirty}>
                  {lang.submitButton}
                </button>
              </form>
            </div>
          </div>
        </div>
      </>
    )
  }
}
