import React from 'react'
import _ from 'lodash'
import { connect, ConnectedProps } from 'react-redux'
import CommonLayout from 'components/common-layout'
import Head from 'components/head'
import lang from 'lang'
import { FETCH_CURRENT_USER } from 'services/actions'
import { wrapper } from 'common/store'
import { getJWT } from 'common/utils'
import { getMoodMeterGroupByShortCodeService } from 'page-modules/mood-meter/service/mood-meter-service'
import InGroupPlot from 'page-modules/mood-meter/in-group/plot'
import InGroupForm from 'page-modules/mood-meter/in-group/form'
import { AppState } from 'common/store/reducer'
import LoadPageData from 'common/utils/load-page-data'

const mapState = (state: AppState) => ({
  group: state.moodMeter.group.item,
  loading: state.moodMeter.group.loading,
})
const connector = connect(mapState)
type Props = ConnectedProps<typeof connector> & {
  hasCode: boolean
}

class InGroup extends React.Component<Props> {
  static getInitialProps = wrapper.getInitialPageProps((store) => async ({ req, res, query }) => {
    if (req) {
      const jwt = getJWT({ req })
      if (jwt) {
        // Don't redirect to login on invalid cookie, because this page is viewable by non-logged users.
        store.dispatch({
          type: FETCH_CURRENT_USER,
          payload: { jwt, res, redirectOnInvalidToken: false },
        })
      }
    }
    if (query.shortCode) {
      await LoadPageData(store, {
        action: getMoodMeterGroupByShortCodeService.actions.request({
          // @ts-expect-error req type mismatch
          req,
          unauthorized: !!req, // Don't authorize the request if a jwt is available, because the request works for non-logged users too. In the case of an invalid jwt tjios request fails, which is not an expected behavior.
          data: { short_code: query.shortCode },
        }),
        confirm: (state) =>
          !state.moodMeter.group.loading && !_.isEmpty(state.moodMeter.group.item),
        error: (state) => !state.moodMeter.group.loading && _.isEmpty(state.moodMeter.group.item),
      })
      const group = store.getState().moodMeter.group.item
      // console.log(group)
      return { hasCode: !!query.shortCode, group }
    }
    return { hasCode: !!query.shortCode }
  })

  get hasGroup() {
    return !_.isEmpty(this.props.group) && !this.props.loading
  }

  render() {
    return (
      <CommonLayout>
        {/* <pre>{JSON.stringify(this.props, null, 2)}</pre> */}
        <style jsx>{`
          .h2.closed {
            margin-top: 3rem;
          }
        `}</style>
        <Head
          loadPageCSS
          title={`${lang.page.moodMeter.plotYourself}${
            this.hasGroup ? ` | ${this.props.group?.name}` : ''
          }`}
        />
        {this.hasGroup && this.props.hasCode && <InGroupPlot />}
        {!this.hasGroup && <InGroupForm hasCode={this.props.hasCode} />}
      </CommonLayout>
    )
  }
}
export default connector(InGroup)
